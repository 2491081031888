@import "../global/style.scss";

.headerContainer {
  height: 6rem;
  padding: 1rem 2rem;
  background-color: $color-component;
  border-bottom: 0.1rem solid $color-border;
  position: sticky;
  top: 0;
  z-index: 100;

  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 15rem;
}

.profileContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.profileIcon {
  font-size: 2.8rem;
}
