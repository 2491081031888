@import "../global/style.scss";

.sidebarContainer {
  width: 20rem;
  height: calc(100vh - 6rem);
  padding-bottom: 2rem;
  background-color: $color-background-dull;
  position: sticky;
  top: 6rem;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 0rem;
  }

  /* Track */
  // &::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  // }

  /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: #888;
  // }

  /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }
}

.navContainer {
  width: 100%;
}

.navListItems {
  list-style: none;
  width: 100%;
  margin: 0;
}

.navItem {
  display: block;
  font-weight: 500;
  padding: 1rem 2rem;
  color: $color-font-primary;
}

.activeNavItem {
  display: block;
  font-weight: 500;
  padding: 1rem 2rem;
  background-color: $color-background-primary;
  border-right: 1rem solid $color-primary;
}
