@import "../global/style.scss";

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 50rem;
  height: fit-content;
  background-color: $color-background-dull;
}

.formItem {
  margin-bottom: 2rem;
}

.formTitle {
  font-weight: 500;
  color: $color-font-dull;
  //   margin-bottom: 1rem;
}

.formDetailContainer {
  margin-top: 0.5rem;
}

.formDetail {
  font-size: 1.4rem;
  color: $color-primary;
}
