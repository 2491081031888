@import "../global/style.scss";

.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-background-dull;
}

.card {
  width: 50rem;
  height: fit-content;
}

.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.logo {
  width: 18rem;
}

.formItem {
  margin-bottom: 2rem;
}

.formTitle {
  font-weight: 500;
  //   margin-bottom: 1rem;
}

.loginButton {
  width: 100%;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-font-secondary;
  background-color: $color-background-secondary;

  &:hover {
    color: $color-font-primary !important;
    background-color: $color-secondary !important;
  }
}
