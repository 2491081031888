@import "../global/style.scss";

.breadcrumb {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: $color-font-dull;
}

.inputSelect {
  width: 100%;
  margin: 0;
  padding: 0;
  color: $color-font-primary !important;
  z-index: 1500 !important;
}

.inputText {
  width: 100%;
  color: $color-font-primary !important;
}

.inputNumber {
  width: 100%;
  color: $color-font-primary !important;
}

.actionButtons {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
}

.secondaryButton {
  flex: 1;
  width: 100%;
  // max-width: 25rem;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-primary;
  background-color: $color-button-blue-shade;
  border: 0.1rem solid $color-primary;

  &:hover {
    color: $color-font-secondary !important;
    background-color: $color-secondary !important;
  }
}

.primaryButton {
  flex: 1;
  width: 100%;
  // max-width: 25rem;
  height: 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-font-secondary;
  background-color: $color-background-secondary;
  border: 0.1rem solid $color-primary;

  &:hover {
    color: $color-font-secondary !important;
    background-color: $color-secondary !important;
  }
}

.defaultIconStyle {
  font-size: 1.8rem;
}

.spinnerContainer {
  height: 60vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 6rem;
  height: 6rem;
}

.headingContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

.pageHeading {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.reloadIcon {
  font-size: 2.4rem;
  cursor: pointer;
}

.backIcon {
  font-size: 2rem;
  color: $color-font-primary;
  cursor: pointer;
}

.emptySpace {
  width: 2rem;
  height: 2rem;
}
