@import "../global/style.scss";

.transactionActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.txnDetailsContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.card {
  flex: 1;
  height: fit-content;
  background-color: $color-background-dull;
}

.detailContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.detailTitle {
  color: $color-font-dull;
}

.detailValue {
  font-weight: 500;
  word-break: break-all;
  text-align: end;
}
