@import "../global/style.scss";

.sectionContainer {
  padding: 2rem;
  background-color: $color-background-dull;
  border: 0.1rem solid $color-border;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.sectionHeading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.detailsContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: $color-background-primary;
  border: 0.1rem solid $color-border;
  border-radius: 2rem;
}

.details {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.detailTitle {
  font-weight: 600;
  color: $color-font-dull;
}
