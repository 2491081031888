@import "../global/style.scss";

.userKYCActions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.userDetailsContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.card {
  flex: 1;
  height: fit-content;
  background-color: $color-background-dull;
}

.detailContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.detailTitle {
  color: $color-font-dull;
}

.detailValue {
  font-weight: 500;
  word-break: break-all;
  text-align: end;
}

.images {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
}

.imageContainer {
  margin-bottom: 2rem;
}

.imageCaption {
  font-weight: 500;
}

.coinDetails {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.coinIcon {
  width: 3.2rem;
  height: 3.2rem;
}
