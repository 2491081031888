//COLORS
$color-primary: #2f66f6; // Nomo Blue
$color-secondary: #519aff; // Nomo Blue

$color-font-primary: #08030b; // on light background
$color-font-secondary: #ffffff; // on dark background
$color-font-dull: #696f8c; // for details or not focused content

$color-background-primary: #ffffff; // screen background
$color-background-secondary: #2f66f6; // screen background
$color-background-dull: #f8f9fb; // screen background
$color-background-dark: #2b2b29; // screen background

$color-border: #d7d9e4;

$color-linetag: #d9d9d9; // line tag

$color-component: #f6f6f6;

$color-button-primary: #2f66f6;
$color-button-secondary: #e0e8fe;
$color-button-blue-shade: rgba(47, 102, 246, 0.15);

//RESPONSIVE BREAKPOINTS
$bp-1440: 1440px;
$bp-1280: 1280px;
$bp-960: 960px;
$bp-840: 840px;
$bp-600: 600px;
$bp-480: 480px;

//FONTS
$font-headings: sans-serif;
$font-text: sans-serif;
$font-navigation: sans-serif;
