.umt-1 {
  margin-top: 1rem !important;
}

.umb-1 {
  margin-bottom: 1rem !important;
}

.umr-1 {
  margin-right: 1rem !important;
}

.uml-1 {
  margin-left: 1rem !important;
}

.umb-0 {
  margin-bottom: 0 !important;
}

.umt-2 {
  margin-top: 2rem !important;
}

.umb-2 {
  margin-bottom: 2rem !important;
}

.umr-2 {
  margin-right: 2rem !important;
}

.uml-2 {
  margin-left: 2rem !important;
}

.umt-3 {
  margin-top: 3rem !important;
}

.umb-3 {
  margin-bottom: 3rem !important;
}

.umr-3 {
  margin-right: 3rem !important;
}

.uml-3 {
  margin-left: 3rem !important;
}

.ub {
  font-weight: bold;
}

.u-green {
  color: #098c26 !important;
}

.u-red {
  color: #cd0000 !important;
}
