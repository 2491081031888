@import "../global/style.scss";

.listItemContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  padding: 2rem;
  background-color: $color-background-dull;
  border: 0.1rem solid $color-border;
  border-radius: 2rem;
  margin-bottom: 2rem;
}

.arrowIcon {
  font-size: 2rem;
}
