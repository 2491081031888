@import "../global/style.scss";

.filterContainer {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.propertyTitle {
  font-weight: 600;
  color: $color-font-dull;
  margin-bottom: 0.5rem;
}

.filterButton {
  max-width: 15rem;
  width: 100%;
  height: 7rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-font-secondary;
  background-color: $color-background-secondary;
  border: 0.1rem solid $color-primary;

  &:hover {
    color: $color-font-secondary !important;
    background-color: $color-secondary !important;
  }
}
