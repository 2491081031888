@import "../global/style.scss";

.bodyContainer {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.contentContainer {
  flex: 1;
  padding: 2rem;
}
